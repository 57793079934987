var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", [_c("h1", [_vm._v("Playlists")])]),
          _c(
            "b-col",
            { staticClass: "text-right", attrs: { cols: "4" } },
            [
              _c(
                "label",
                { staticClass: "mr-4", attrs: { for: "text-search" } },
                [_vm._v("Zoeken")]
              ),
              _c("b-form-input", {
                staticClass: "inline mr-4",
                attrs: { id: "text-search" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.setSearch($event)
                  }
                },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("label", { attrs: { for: "label-select" } }, [
                _vm._v("Label")
              ]),
              _c("v-select", {
                attrs: {
                  id: "label-select",
                  placeholder: "---- Alles ----",
                  label: "Name",
                  reduce: function(label) {
                    return label.LabelID
                  },
                  options: _vm.labelOptions
                },
                model: {
                  value: _vm.filter.LabelID,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "LabelID", $$v)
                  },
                  expression: "filter.LabelID"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c("label", { attrs: { for: "release-date" } }, [
                _vm._v("Aangemaakt op")
              ]),
              _c("b-form-datepicker", {
                attrs: { "reset-button": true, id: "release-date" },
                model: {
                  value: _vm.filter.ReleaseDate,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "ReleaseDate", $$v)
                  },
                  expression: "filter.ReleaseDate"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c("label", { attrs: { for: "status-select" } }, [
                _vm._v("Status")
              ]),
              _c("v-select", {
                attrs: {
                  id: "status-select",
                  placeholder: "---- Alles ----",
                  reduce: function(status) {
                    return status.code
                  },
                  options: _vm.statuses
                },
                model: {
                  value: _vm.filter.DeliveryStatusID,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "DeliveryStatusID", $$v)
                  },
                  expression: "filter.DeliveryStatusID"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticStyle: { "padding-top": "1.75em" }, attrs: { cols: "2" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  staticStyle: { width: "45%" },
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setSearch($event)
                    }
                  }
                },
                [_vm._v("Zoeken")]
              ),
              _c(
                "b-button",
                {
                  staticStyle: { width: "45%" },
                  attrs: { variant: "white" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetSearch($event)
                    }
                  }
                },
                [_vm._v("Reset")]
              )
            ],
            1
          )
        ],
        1
      ),
      false
        ? _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "i",
                    {
                      staticStyle: {
                        "font-weight": "bold",
                        "font-size": "18px",
                        color: "#bf0000"
                      }
                    },
                    [_vm._v("»")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "ml-2",
                      attrs: { to: { name: "release-concepts-create" } }
                    },
                    [_vm._v("Nieuwe playlist invoeren...")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("rest-table", {
                attrs: {
                  endpoint: "albums",
                  filter: _vm.activeFilter,
                  fields: _vm.fields,
                  "start-at": _vm.startAt
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(artistTitle)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "release-detail",
                                params: { id: data.item.AlbumID }
                              }
                            }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(data.item.ProductDetails.Attribution)
                              )
                            ]),
                            _vm._v(
                              " - " +
                                _vm._s(data.item.ProductDetails.Title) +
                                " " +
                                _vm._s(data.item.ProductDetails.VersionTitle) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(ReleaseDate)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("dateFormat")(data.item.ReleaseDate)
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(ProductDetails.DeliveryStatus.Description)",
                    fn: function(data) {
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.deliveryStatusFormatter(data.item)
                            )
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "cell(CoverArtAvailable)",
                    fn: function(data) {
                      return [
                        data.item.CoverArtAvailable == "1"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "green"
                                }
                              },
                              [_vm._v("V")]
                            )
                          : _vm._e(),
                        data.item.CoverArtAvailable == "0"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  color: "red"
                                }
                              },
                              [_vm._v("X")]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(data) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "release-detail",
                                params: { id: data.item.AlbumID }
                              }
                            }
                          },
                          [_c("b-icon-pencil-square")],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }