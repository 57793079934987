<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col>
        <h1>Playlists</h1>
      </b-col>

      <b-col cols="4" class="text-right">
        <label for="text-search" class="mr-4">Zoeken</label>
        <b-form-input id="text-search" class="inline mr-4" @keyup.enter="setSearch" v-model="filter.q"></b-form-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <label for="label-select">Label</label>
        <v-select
          v-model="filter.LabelID"
          id="label-select"
          placeholder="---- Alles ----"
          label="Name"
          :reduce="label => label.LabelID"
          :options="labelOptions">
        </v-select>
      </b-col>

      <b-col cols="2">
        <label for="release-date">Aangemaakt op</label>
        <b-form-datepicker 
            :reset-button="true"
            v-model="filter.ReleaseDate"
            id="release-date">

        </b-form-datepicker>
      </b-col>

      <b-col cols="2">
        <label for="status-select">Status</label>
        <v-select
          v-model="filter.DeliveryStatusID"
          id="status-select"
          placeholder="---- Alles ----"
          :reduce="status => status.code"
          :options="statuses">
        </v-select>
      </b-col>

      <b-col cols="2" style="padding-top: 1.75em;">
        <b-button variant="primary" class="mr-2" style="width: 45%;" v-on:click.prevent="setSearch">Zoeken</b-button>
        <b-button variant="white" style="width: 45%;" v-on:click.prevent="resetSearch">Reset</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3" v-if="false">
      <b-col>
        <i style="font-weight:bold; font-size:18px ;color:#bf0000">»</i>
        <router-link :to="{ name: 'release-concepts-create' }" class="ml-2">Nieuwe playlist invoeren...</router-link>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <rest-table
            endpoint="albums"
            :filter="activeFilter"
            :fields="fields"
            :start-at="startAt"
          >

          <template v-slot:cell(artistTitle)="data">
            <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
              <strong>{{ data.item.ProductDetails.Attribution }}</strong> - {{ data.item.ProductDetails.Title }} {{ data.item.ProductDetails.VersionTitle }}
            </router-link>
          </template>

          <template v-slot:cell(ReleaseDate)="data">
            {{ data.item.ReleaseDate|dateFormat }}
          </template>

          <template v-slot:cell(ProductDetails.DeliveryStatus.Description)="data">
            <div v-html="deliveryStatusFormatter(data.item)"></div>
          </template>

          <template v-slot:cell(CoverArtAvailable)="data">
            <span v-if="data.item.CoverArtAvailable == '1'" style="font-weight: bold; color: green">V</span>
            <span v-if="data.item.CoverArtAvailable == '0'" style="font-weight: bold; color: red">X</span>
          </template>

          <template v-slot:cell(actions)="data">
            <router-link :to="{ name: 'release-detail', params: { id: data.item.AlbumID } }">
              <b-icon-pencil-square></b-icon-pencil-square>
            </router-link>
          </template>
        </rest-table>
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import vSelect from 'vue-select'
  import RestTable from '@/components/RestTable.vue'
  //import _ from 'lodash'

  export default {
    name: 'Releases',
    components: {
      RestTable,
      vSelect,
    },
    data () {
      return {
      
        labelOptions: [],
        filter: {
          q: '',
          LabelID: '',
          DeliveryStatusID: '',
          ReleaseDate: ''
        },
        statuses: [],
        fields: [
          {
            key: 'idx',
            label: '#'
          },
          {
            key: 'artistTitle',
            label: 'Artiest en Titel',
            sortable: true,
          },
          {
            key: 'Label',
            label: 'Label',
            sortable: true,
          },
          {
            key: 'ReleaseDate',
            label: 'Datum',
            sortable: true,
          },
          {
            key: 'TotalTrackCount',
            label: 'Aant.',
            sortable: true,
          },
          {
            key: 'ProductDetails.DeliveryStatus.Description',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'CoverArtAvailable',
            label: 'C',
            headerTitle: 'Cover beschikbaar',
            sortable: true,
          },
          {
            key: 'ProductDetails.ReportingCode',
            label: 'Boep ID',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Acties',
            class: 'link-icons',
          },
        ],
      }
    },
    props: {
      activeFilter: Object,
      startAt: Number,
    },
    watch: {
      '$route' () {
        this.loadFilter()
      }
    },
    methods: {
      loadFilter () {
        // Load filter from query
        this.filter.q = this.activeFilter.q
        this.filter.LabelID = this.activeFilter.LabelID
      },
      setSearch () {
        if (JSON.stringify(this.filter) == JSON.stringify(this.activeFilter)) return
        this.$router.push({ 
          query: {
            'start-at': 1,
            q: this.filter.q,
            labelId: this.filter.LabelID,
            deliveryStatusID: this.filter.DeliveryStatusID,
            releaseDate: this.filter.ReleaseDate,
          }
        })
      },
      resetSearch() {
        this.filter.q = ''
        this.filter.ReleaseDate = ''
        this.filter.LabelID = ''
        this.filter.DeliveryStatusID = ''
        
        this.setSearch()
      },
      deliveryStatusFormatter(data) {
        const ds = data.ProductDetails.DeliveryStatus
        let desc = this.statuses.find(s => {return s.code == ds.StatusID})
        if (desc) {
          desc = desc.label
        } else {
          desc = ds.Description
        }

        return desc 
      }
      /*
      fetchLabelOptions: _.debounce(function (search, loading) {
        
        loading(true)

        let ctx = {
          perPage: 10000,
          currentPage: 1,
          filter: { q: search },
        }
        this.$http
          .get('labels', { params: ctx })
          .then(response => {
              
              loading(true) 
              this.response.data)

          }).catch(function () {
              resolve([])
          })
          
      }, 300)
      */
    },
    beforeRouteLeave (to, from, next) {
      localStorage.setItem('search-release-last', JSON.stringify(from.query))
      next()
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'release-search' }, text: 'Playlists'}
      ]

      // Set delivery status options
      for (let ds of this.$root.$data.fieldDefs.Album.AlbumDeliveryStatus.Options) {
        this.statuses.push({
          code: ds.JsonValue,
          label: ds.Value,
        })
      } 

      // Load label options
      let ctx = {
        perPage: 10000,
        currentPage: 1,
      }
      this.$http
        .get('labels', { params: ctx })
        .then(response => {
          this.labelOptions = response.data
        })
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>